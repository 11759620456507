export const initResultsCountObserver = () => {
  const filterCountWrapper = document.querySelector('#js-answersVerticalResultsCount');
  if (filterCountWrapper) {
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes) {
          for (const node of mutation.addedNodes) {
            if (node.classList?.contains('yxt-VerticalResultsCount')) {
              const verticalResultsCountDiv = document.querySelector('.yxt-VerticalResultsCount');
              const resultsCountSpan = document.querySelector('.yxt-VerticalResultsCount-total');

              if (resultsCountSpan) {
                const resultsCount = Number(resultsCountSpan.innerHTML);
                if (resultsCount > 100) {
                  if (verticalResultsCountDiv) {
                    const ariaLabel = verticalResultsCountDiv.getAttribute('aria-label').replace(resultsCountSpan.innerHTML, '100');
                    verticalResultsCountDiv.setAttribute('aria-label', ariaLabel);
                  }

                  resultsCountSpan.innerHTML = '100';
                  const resultsEndSpan = document.querySelector('.yxt-VerticalResultsCount-end');
                  if (resultsEndSpan) {
                    const resultsEnd = Number(resultsEndSpan.innerHTML);
                    if (resultsEnd == 100) {
                      const resultsNext = document.querySelector('.js-yxt-Pagination-next');
                      if (resultsNext) {
                          resultsNext.style.display = 'none';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const observer = new MutationObserver(callback);
    observer.observe(filterCountWrapper, config);
  };
}
